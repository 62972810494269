import React from 'react';

import useAllPosts from 'hooks/graphql/useAllPosts';
import useAllCategoties from 'hooks/graphql/useAllCategories';

import Hero from './Hero';
import List from './List';

const MainPage = (page) => {
  const posts = useAllPosts();
  const categories = useAllCategoties();

  // // eslint-disable-next-line no-console
  // console.dir({ page, posts, categories });

  return (
    <div>
      <Hero />
      <List />
    </div>
  );
};

export default MainPage;
