import {
  useStaticQuery,
  graphql
} from 'gatsby';

const allPosts = graphql`
  query AllWpPosts {
    allWpPost {
      nodes {
        id
        uri
        title
        slug
        content
        excerpt
        date
        featuredImage {
          node {
            altText
            sourceUrl
            srcSet
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        categories {
          nodes {
            name
            slug
            uri
          }
        }
        author {
          node {
            lastName
            firstName
            slug
            username
            email
            description
            avatar {
              url
            }
            seo {
              social {
                facebook
                twitter
              }
            }
          }
        }
        relatedPosts {
          relatedPosts {
            relatedPostItemElement {
              ... on WpPost {
                id
                date
                title
                categories {
                  nodes {
                    name
                    slug
                    uri
                  }
                }
                author {
                  node {
                    name
                    email
                    avatar {
                      url
                    }
                    description
                    seo {
                      social {
                        facebook
                        twitter
                      }
                    }
                  }
                }
                tags {
                  nodes {
                    name
                    slug
                    uri
                  }
                }
                categories {
                  nodes {
                    name
                    slug
                    uri
                  }
                }
                featuredImage {
                  node {
                    sourceUrl
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const useAllPosts = () => {
  const {
    allWpPost: {
      nodes
    },
  } = useStaticQuery(allPosts);

  return nodes;
};

export default useAllPosts;
