import React, { useMemo } from 'react';
import { useLocation } from '@reach/router';
import classnames from 'classnames';
import { parse } from 'query-string';

import Link from 'components/Link';

const Item = ({ category: { id, name, link, slug }, className, activeClasses }) => {
  const { search } = useLocation();
  const { category } = useMemo(() => parse(search), [search]);

  return (
    <li 
      key={id}
      className={classnames(
        ((category === slug) || (!category && !slug)) && activeClasses,
        className
      )}
    >
      <Link
        href={link}
      >{name}</Link>
    </li>
  );
}

export default Item;