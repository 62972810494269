import React from 'react';
import Categories from './Categories/index';
import Subscribe from 'components/common/Subscribe';

import Items from './Items';

const List = () => {

  return (
    <div>
      <div className="fluid-container lg:pt-16 md:pt-8 pt-6 flex flex-col lg:flex-row-reverse">
        <Categories />
        <Items />
      </div>

      <div className="fluid-container mt-16 lg:mt-18">
        <Subscribe />
      </div>
    </div>
  )
}

export default List;