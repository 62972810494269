import React from 'react';

import useAllCategoties from 'hooks/graphql/useAllCategories';
import Item from './Item';

const Categories = (props) => {
  const categories = useAllCategoties();

  return (
    <div className="lg:basis-1/3 mb-8 lg:mb-0">
      <div className="lg:sticky lg:top-10">
        <ul className="flex flex-row lg:flex-col lg:pl-28 flex-wrap">
          {
            categories.map(category =>
              <Item
                key={category.id}
                category={category}
                className="lg:h-16 flex flex-row items-center justify-between border-grey-3 border-r lg:border-b lg:border-r-0 lg:first:border-t relative hover:text-red my-1 px-5 lg:my-0 lg:px-0 whitespace-nowrap"
                activeClasses="text-red font-medium after:w-2 after:h-2 after:bg-red after:absolute after:top-1.5 after:left-1.5 lg:after:static"
              />
            )
          }
        </ul>
      </div>
    </div>
  );
}

export default Categories;