import React, { useCallback } from "react";
import PaginationComponent from 'react-paginating';
import classnames from 'classnames';
import { ChevronLeft, ChevronRight } from 'react-feather';
import { useLocation } from '@reach/router';
import { parse, stringify } from 'query-string';

import Link from "components/Link";

const Pagination = ({ pageNumber, allPosts, itemsPerPage, pageCount }) => {
  const { search } = useLocation();
  const params = parse(search);

  const countLinks = 6;

  const getURLRoute = useCallback((page) => {
    let strParams;

    if (page === 1) {
      const { page, ...parameters } = params;
      strParams = stringify(parameters);
    } else {
      strParams = stringify({ ...params, page: page });
    }

    return `?${strParams}`;
  }, [params]);

  if (pageCount === 1) return;

  return (
    <PaginationComponent
      className=""
      total={allPosts}
      limit={itemsPerPage}
      pageCount={countLinks}
      currentPage={pageNumber}
    >
      {({
        pages,
        currentPage,
        hasNextPage,
        hasPreviousPage,
        previousPage,
        nextPage,
        totalPages,
        getPageItemProps
      }) => (
        <div className="flex space-x-1 sm:space-x-3 items-center">
          {/* {hasPreviousPage && (
            <Link
              className={classnames(
                'flex items-center justify-center w-12 h-12 rounded-md border border-grey-3',
                !hasPreviousPage && 'pointer-events-none opacity-50'
              )}
              {...getPageItemProps({
                pageValue: 1,
              })}
              to={getURLRoute(1)}
              title="Go to the first page"
            >
              First
            </Link>
          )} */}

          {/* {hasPreviousPage && ( */}
            <Link
              className={classnames(
                'flex items-center justify-center w-12 h-12 rounded-md border border-grey-3',
                !hasPreviousPage && 'pointer-events-none opacity-50'
              )}
              {...getPageItemProps({
                pageValue: previousPage,
              })}
              to={getURLRoute(previousPage)}
              title={`Go to the page number ${previousPage}`}
            >
              <ChevronLeft />
            </Link>
          {/* )} */}

          {
            pages.map(page =>
              <Link
                className={classnames(
                  'flex items-center justify-center w-12 h-12 rounded-md border border-grey-3',
                  { 'bg-grey-3 pointer-events-none': currentPage === page }
                )}
                {...getPageItemProps({
                  pageValue: page,
                  key: page,
                })}
                to={getURLRoute(page)}
                title={`Go to the page number ${page}`}
              >
                {page}
              </Link>
            )
          }

          {/* {hasNextPage && ( */}
            <Link
            className={classnames(
              'flex items-center justify-center w-12 h-12 rounded-md border border-grey-3',
              !hasNextPage && 'pointer-events-none opacity-50'
            )}
              {...getPageItemProps({
                pageValue: nextPage,
              })}
              to={getURLRoute(nextPage)}
              title={`Go to the page number ${nextPage}`}
            >
              <ChevronRight />
            </Link>
          {/* )} */}

          {/* {hasNextPage && (
            <Link
              // className={classnames(``, { 'is-disable-pagin': !hasNextPage })}
              className={classnames(
                'flex items-center justify-center w-12 h-12 rounded-md border border-grey-3',
                !hasNextPage && 'pointer-events-none opacity-50'
              )}
              {...getPageItemProps({
                pageValue: totalPages,
              })}
              to={getURLRoute(totalPages)}
              title="Go to the last page"
            >
              Last
            </Link>
          )} */}
        </div>
      )}
    </PaginationComponent>
  );
}

export default Pagination;
