const {
  commonPageData
} = require('../commonData.js');

// TODO: Подставить приведенные функции по мере добавлени полей на страницы
//    ... on ${WpTag}Template_Request {
//     ${request(WpTag)}
//   }

const page = (WpTag = '') => `
  ${commonPageData(WpTag)}
`;

module.exports.page = page;
