import React from 'react';
import classnames from 'classnames';

import * as styles from './styles.module.scss';
import BgBricks from 'assets/bg/bricks.inline.svg';
import CrossImage from 'assets/icons/cross.inline.svg';
import CrossText from 'assets/icons/cross-text.inline.svg';

const Hero = () => {
  return (
    <div className={classnames(
      'relative',
      styles.top  
    )}>
      <div className="fluid-container relative flex flex-col justify-end sm:justify-center h-64 sm:h-56 lg:h-96 pb-4 sm:pb-0">
        <BgBricks 
          className={classnames(
            'absolute top-40',
            styles.top_bricks
          )}
        />
        <div
          className="absolute top-14 sm:top-14 lg:top-32 left-1/2 sm:left-[60%] lg:left-1/2 w-24 sm:w-32 lg:w-40 h-24 sm:h-32 lg:h-40"  
        >
          <CrossImage
            className={styles.top_cross} 
          />
          <CrossText
            className={styles.top_text} 
          />
        </div>
        <h1 className="text-h1 text-black font-medium">Blog</h1>
        <p className="text-lg sm:w-96 lg:w-[456px] pt-6 lg:pt-12">Welcome to <span className="text-red">Oncology Compass blog</span>, where we post our own researches and articles. </p>
      </div>
    </div>
  )
}

export default Hero;