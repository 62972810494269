import { useMemo } from 'react';
import { useLocation } from '@reach/router';
import { parse } from 'query-string';

import useAllPosts from 'hooks/graphql/useAllPosts';
import useAllCategoties from 'hooks/graphql/useAllCategories';

const useFilteredPosts = () => {
  const allPosts = useAllPosts();

  const categories = useAllCategoties();

  const { search } = useLocation();
  const { category } = useMemo(() => parse(search), [search]);

  const posts = useMemo(() => {
    if (!category) return allPosts;

    const currentCategory = categories
      .find(item => item.slug === category);

    if (!currentCategory) return allPosts;

    const currentCatPosts = currentCategory.posts.nodes.map(({ id }) => id);

    return allPosts.filter(post => currentCatPosts.includes(post.id));
  }, [categories, category, allPosts]);

  return posts;
};

export default useFilteredPosts;