import React, { useMemo } from 'react';
import { useLocation } from '@reach/router';
import { parse } from 'query-string';

import * as styles from './styles.module.scss';

import useFilteredPosts from 'hooks/useFilteredPosts';
import useAllSettings from 'hooks/graphql/useAllSettings';

import PreviewImage from 'components/ui/PreviewImage';
import PreviewText from 'components/ui/PreviewText';
import Link from 'components/Link';
import DateCategory from 'components/post/DateCategory';
import Pagination from 'components/Pagination';

const Items = () => {
  const posts = useFilteredPosts();

  const { search } = useLocation();
  const { page = 1 } = useMemo(() => parse(search), [search]);

  const {
    allSettings: {
      readingSettingsPostsPerPage: postPerPage
    }
  } = useAllSettings();

  // Для тестирования пагинации
  // const postPerPage = 2;

  const currentItems = useMemo(() => {
    const curPage = Number(page) - 1;

    const endOffset = curPage * postPerPage + postPerPage;

    return posts.sort((a, b) => new Date(b.date) - new Date(a.date))
      .slice(curPage * postPerPage, endOffset);
  }, [page, postPerPage, posts]);


  const pageCount = useMemo(() =>
    Math.ceil(posts.length / postPerPage),
    [postPerPage, posts.length]
  );

  const postsCount = useMemo(() => posts.length, [posts.length]);

  return (
    <div className="lg:basis-2/3">
      <ul className="">
        {currentItems.map(post => (
          <li
            key={post.id}
            className="pt-8 lg:pt-12 first:pt-0"
          >
            <Link
              href={post.uri}
              className={styles.item}
            >
              <PreviewImage
                img={post.featuredImage?.node}
                placeholder={"BLURRED"}
                className={styles.img}
              />
              <DateCategory
                className="pt-6 lg:pt-8"
                date={post.date}
                categories={post.categories?.nodes || []}
              />

              <h4 className="text-h4 font-medium pt-4">{post.title}</h4>
              <PreviewText
                text={post.excerpt}
                className="pt-6 text-text-2"
              />
            </Link>
          </li>
        ))}
      </ul>

      <div className="mt-10 lg:mt-15">
        <Pagination
          currentPage={Number(page)}
          pageNumber={Number(page)}
          pageCount={pageCount}
          allPosts={postsCount}
          itemsPerPage={postPerPage}
        />
      </div>
    </div>
  )
}

export default Items;