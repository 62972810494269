import {
  useStaticQuery,
  graphql
} from 'gatsby';

const allSettingsQuery = graphql`
  query AllWpSettings {
    allWp {
      nodes {
        allSettings {
          readingSettingsPostsPerPage
        }
      }
    }
  }
`;

const useAllSettings = () => {
  const {
    allWp: {
      nodes
    },
  } = useStaticQuery(allSettingsQuery);

  return nodes[0];
};

export default useAllSettings;
